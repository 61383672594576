<template>
  <div>
    <ClientDetailItemEditCard confirmation-message="Contact informatie werd succesvol gewijzigd"
                              title="Bewerk contact informatie" :clientId="clientId"
                              update-store-action="clientsModule/updateContactInformation"
                              :update-store-action-payload="{clientId: clientId,  contactInformation: contactInformation}"
                              detail-view-route-location=""
    >
      <template v-slot:form-fields>
        <v-row>
          <v-col>
            <EditExternalContacts v-for="(contact, index) in contactInformation.contactList"
                                  v-model="contactInformation.contactList[index]" v-bind:key="index"/>
          </v-col>
        </v-row>
      </template>
    </ClientDetailItemEditCard>
  </div>
</template>

<script>
import ClientDetailItemEditCard from "@/views/client/forms/ClientDetailItemEditCard";
import EditExternalContacts from "../../../components/shared/fields/EditExternalContacts.vue"

export default {
  name: "ClientDetailEditContactInformation",
  props: {
    clientId: {
      type: String,
      required: true
    }
  },
  components: {
    ClientDetailItemEditCard,
    EditExternalContacts
  },
  data() {
    return {
      contactInformation: {
        contactList: [
          {
            firstName: null,
            lastName: null,
            phone: null,
            email: null,
            primaryContact: null,
            jobTitle: null
          }
        ]
      },
      promises: [
        this.$store.dispatch('clientsModule/fetchContactInformation', {clientId: this.clientId})
            .then((contactInformation) => {
              this.contactInformation = contactInformation
              if (!this.contactInformation.contactList) {
                this.contactInformation.contactList = [{primaryContact: true}]
              }
            })
      ]
    }
  }
}
</script>
